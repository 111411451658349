import React, { Fragment, useState } from 'react';
import { TextField } from '@cimpress/react-components';
import { OrderItemWithStatusAndOrderInfo } from '../../../clients/foma/itemsClient';
import { useTranslation } from 'react-i18next';
import { TrackedButton } from '../../../trackingLayer/trackedButton';
import { TrackedModal } from '../../../trackingLayer/trackedModal';
import { holdProduction } from '../../../store/items/actions';
import { ResponsePostProductionHoldItemsV1 } from '@cimpress-technology/supplier-integrations-client';

export const HoldProductionButton: React.FC<HoldProductionButtonProps> = props => {
  const { t } = useTranslation();

  const initialState = { open: false, artworkIssueDescription: '' };
  const [state, setState] = useState(initialState);

  const openModal = () => {
    setState({ ...state, open: true });
  };

  const closeModal = () => {
    setState(initialState);
  };

  const onInputChange = (key, value) => setState({ ...state, [key]: value });

  const holdProductionAction = () => {
    closeModal();

    const holdProductionOptions: Omit<ResponsePostProductionHoldItemsV1['productionHoldItems'], 'items'> = {
      type: 'artwork', // This is currently hardcoded to 'artwork', but the future plan is to introduce categories from where users can select their type
      description: state.artworkIssueDescription
    };

    props.dispatch(holdProduction(props.items, holdProductionOptions, props.refreshItemList));
  };

  return (
    <Fragment>
      {props.dropDown
        ? <button className={props.className} onClick={openModal} disabled={props.disabled}>
          {t('items.actions.holdProduction.actionButtonLabel')}
        </button>
        : <TrackedButton item="rejectModal" className={props.className} type={'link'} onClick={openModal} disabled={props.disabled}>
          {t('items.actions.holdProduction.actionButtonLabel')}
        </TrackedButton>}
      <span onClick={e => e.stopPropagation()}>
        <TrackedModal item={'holdProductionModal'}
          closeButton={true}
          show={state.open}
          onRequestHide={closeModal}
          title={t('items.actions.holdProduction.modalTitle', { count: props.items.length })}
          footer={
            <Fragment>
              <TrackedButton item="rejectModal.cancel" className="btn btn-default" onClick={closeModal}>
                {t('items.actions.holdProduction.modalFooterButtons.cancel')}
              </TrackedButton>
              <TrackedButton item="rejectModal.reject" className="btn" type={'primary'} disabled={!state.artworkIssueDescription} onClick={holdProductionAction}>
                {t('items.actions.holdProduction.modalFooterButtons.holdProduction', { count: props.items.length })}
              </TrackedButton>
            </Fragment>
          }
        >
          <>
            {t('items.actions.holdProduction.holdProductionDescription')}

            <br />
            <br />
            <TextField
              type='textarea'
              value={state.artworkIssueDescription}
              onChange={e => onInputChange('artworkIssueDescription', e.target.value)}
              label={t('items.actions.holdProduction.artworkIssue')}
              helpText={t('items.actions.holdProduction.artworkIssueMessage')}
            />
          </>
        </TrackedModal>
      </span>
    </Fragment>
  );
};

interface HoldProductionButtonProps {
  dispatch: Function;
  items: OrderItemWithStatusAndOrderInfo[];
  disabled?: boolean;
  className?: string;
  refreshItemList: boolean;
  dropDown?: boolean;
}

