/*
 * To anyone editing this file:
 * Please follow the convention of putting the namespace in the enums
 * Without this convention we might accidentally run into collisions
 */

export enum AuthActions {
  REFRESH_TOKEN = 'AUTH_REFRESH_TOKEN',
  REFRESH_PROFILE = 'AUTH_REFRESH_PROFILE',
  LOGOUT = 'AUTH_LOGOUT',
  FAILURE = 'AUTH_FAILURE'
}

export enum DashboardActions {
  GET_TODAY_WIDGET_VALUES_REQUEST = 'DASHBOARD_GET_TODAY_WIDGET_VALUES_REQUEST',
  GET_TODAY_WIDGET_VALUES_SUCCESS = 'DASHBOARD_GET_TODAY_WIDGET_VALUES_SUCCESS',
  GET_TODAY_WIDGET_VALUES_ERROR = 'DASHBOARD_GET_TODAY_WIDGET_VALUES_ERROR',
  GET_BACKLOG_VALUES_REQUEST = 'DASHBOARD_GET_BACKLOG_VALUES_REQUEST',
  GET_BACKLOG_VALUES_SUCCESS = 'DASHBOARD_GET_BACKLOG_VALUES_SUCCESS',
  GET_BACKLOG_VALUES_ERROR = 'DASHBOARD_GET_BACKLOG_VALUES_ERROR',
  GET_PRODUCT_CATEGORY_TREE_ERROR = 'DASHBOARD_GET_PRODUCT_CATEGORY_TREE_ERROR',
  TOGGLE_GROUP_BY_PRODUCT_CATEGORIES = 'DASHBOARD_TOGGLE_GROUP_BY_PRODUCT_CATEGORIES',
  TOGGLE_BACKLOG_BY_QUANTITIES = 'DASHBOARD_TOGGLE_BACKLOG_BY_QUANTITIES',
}

export enum ItemsActions {
  GET_ITEMS_REQUEST = 'ITEMS_GET_ITEMS_REQUEST',
  GET_ITEMS_SUCCESS = 'ITEMS_GET_ITEMS_SUCCESS',
  GET_ITEMS_ERROR = 'ITEMS_GET_ITEMS_ERROR',
  GET_FOMA_ITEM_REQUEST = 'ITEMS_GET_FOMA_ITEM_REQUEST',
  GET_FOMA_ITEM_SUCCESS = 'ITEMS_GET_FOMA_ITEM_SUCCESS',
  GET_FOMA_ITEM_ERROR = 'ITEMS_GET_FOMA_ITEM_ERROR',
  SET_ITEMS_FILTERS = 'ITEMS_SET_ITEMS_FILTERS',
  SET_ITEMS_TABLE_STATE = 'ITEMS_SET_ITEMS_TABLE_STATE',
  TOGGLE_ROW_SELECTION = 'ITEMS_TOGGLE_ROW_SELECTION',
  TOGGLE_ALL_SELECTION = 'ITEMS_TOGGLE_ALL_SELECTION',
  BULK_ACCEPT_REQUEST = 'ITEMS_BULK_ACCEPT_REQUEST',
  BULK_ACCEPT_DONE = 'ITEMS_BULK_ACCEPT_DONE',
  SHOW_ALERT = 'ITEMS_SHOW_ALERT',
  DISMISS_ALERT = 'ITEMS_DISMISS_ALERT',
  BULK_SET_TO_PRODUCTION = 'ITEMS_BULK_SET_TO_PRODUCTION',
  BULK_SET_TO_PRODUCTION_DONE = 'ITEMS_BULK_SET_TO_PRODUCTION_DONE',
  BULK_REJECT_ORDER = 'ITEMS_BULK_REJECT_ORDER',
  BULK_REJECT_ORDER_DONE = 'ITEMS_BULK_REJECT_ORDER_DONE',
  NOTIFY_DELAY_DONE = 'ITEMS_NOTIFY_DELAY_DONE',
  HOLD_PRODUCTION_DONE = 'ITEMS_HOLD_PRODUCTION_DONE',
  BULK_NOTIFY_DELAY = 'ITEMS_BULK_NOTIFY_DELAY',
  BULK_NOTIFY_DELAY_DONE = 'ITEMS_BULK_NOTIFY_DELAY_DONE',
  GET_PLATFORM_ITEMS_REQUEST = 'ITEMS_GET_PLATFORM_ITEMS_REQUEST',
  GET_PLATFORM_ITEMS_DONE = 'ITEMS_GET_PLATFORM_ITEMS_DONE'
}

export enum SettingsActions {
  SET_LOCALE = 'SETTINGS_SET_LOCALE',
  SET_SETTNGS = 'SET_SETTNGS',
  SET_COLUMN_WIDTHS = 'SET_COLUMN_WIDTHS'
}

export enum FulfillersActions {
  GET_FULFILLERS_REQUEST = 'FULFILLERS_GET_FULFILLERS_REQUEST',
  GET_FULFILLERS_SUCCESS = 'FULFILLERS_GET_FULFILLERS_SUCCESS',
  GET_FULFILLERS_ERROR = 'FULFILLERS_GET_FULFILLERS_ERROR',
  SET_SELECTED_FULFILLER_ID = 'FULFILLERS_SET_SELECTED_FULFILLER_ID',
  SET_SELECTED_FULFILLERS = 'FULFILLERS_SET_SELECTED_FULFILLERS',
  GET_SELECTED_FULFILLER_IDS_REQUEST = 'FULFILLERS_GET_SELECTED_FULFILLER_IDS_REQUEST',
  GET_SELECTED_FULFILLER_IDS_ERROR = 'FULFILLERS_GET_SELECTED_FULFILLER_IDS_ERROR',
  GET_SELECTED_FULFILLER_IDS_SUCCESS = 'FULFILLERS_GET_SELECTED_FULFILLER_IDS_SUCCESS'
}

export enum ViewsActions {
  GET_VIEWS_REQUEST = 'VIEWS_GET_VIEWS_REQUEST',
  GET_VIEWS_SUCCESS = 'VIEWS_GET_VIEWS_SUCCESS',
  GET_VIEWS_ERROR = 'VIEWS_GET_VIEWS_ERROR',
}

export enum ProductCategoriesActions {
  GET_PRODUCT_CATEGORIES_REQUEST = 'PRODUCTS_GET_PRODUCT_CATEGORIES_REQUEST',
  GET_PRODUCT_CATEGORIES_SUCCESS = 'PRODUCTS_GET_PRODUCT_CATEGORIES_SUCCESS',
  GET_PRODUCT_CATEGORIES_ERROR = 'PRODUCTS_GET_PRODUCT_CATEGORIES_ERROR',
}

export enum DeliveryOptionsActions {
  GET_DELIVERY_OPTIONS_REQUEST = 'DELIVERY_OPTIONS_GET_DELIVERY_OPTIONS_REQUEST',
  GET_DELIVERY_OPTIONS_SUCCESS = 'DELIVERY_OPTIONS_GET_DELIVERY_OPTIONS_SUCCESS',
  GET_DELIVERY_OPTIONS_ERROR = 'DELIVERY_OPTIONS_GET_DELIVERY_OPTIONS_ERROR',
}

export enum DeliveryRequestActions {
  GET_DELIVERY_REQUEST_DETAILS_REQUEST = 'DELIVERY_REQUEST_GET_DELIVERY_DETAILS_REQUEST',
  GET_DELIVERY_REQUEST_DETAILS_SUCCESS = 'DELIVERY_REQUEST_GET_DELIVERY_DETAILS_SUCCESS',
  GET_DELIVERY_REQUEST_DETAILS_ERROR = 'DELIVERY_REQUEST_GET_DELIVERY_DETAILS_ERROR',
}

export enum JobsheetPresetActions {
  GET_JOBSHEET_PRESET_REQUEST = 'JOBSHEET_GET_JOBSHEET_PRESET_REQUEST',
  GET_JOBSHEET_PRESET_SUCCESS = 'JOBSHEET_GET_JOBSHEET_PRESET_SUCCESS',
  GET_JOBSHEET_PRESET_ERROR = 'JOBSHEET_GET_JOBSHEET_PRESET_ERROR'
}

export enum NotificationsActions {
  GET_NOTIFICATIONS_REQUEST = 'NOTIFICATIONS_GET_NOTIFICATIONS_REQUEST',
  GET_NOTIFICATIONS_ERROR = 'NOTIFICATIONS_GET_NOTIFICATIONS_ERROR',
  GET_NOTIFICATIONS_SUCCESS = 'NOTIFICATIONS_GET_NOTIFICATIONS_SUCCESS',
  GET_NOTIFICATIONS_FOR_ITEM_REQUEST = 'GET_NOTIFICATIONS_FOR_ITEM_REQUEST',
  GET_NOTIFICATIONS_FOR_ITEM_ERROR = 'GET_NOTIFICATIONS_FOR_ITEM_ERROR',
  GET_NOTIFICATIONS_FOR_ITEM_SUCCESS = 'GET_NOTIFICATIONS_FOR_ITEM_SUCCESS',
  HANDLE_NOTIFICATIONS_REQUEST = 'NOTIFICATIONS_HANDLE_NOTIFICATIONS_REQUEST',
  HANDLE_NOTIFICATIONS_ERROR = 'NOTIFICATIONS_HANDLE_NOTIFICATIONS_ERROR',
  HANDLE_NOTIFICATIONS_SUCCESS = 'NOTIFICATIONS_HANDLE_NOTIFICATIONS_SUCCESS',
}

export enum SnackbarActions {
  SHOW_SNACKBAR = 'SHOW_SNACKBAR',
  HIDE_SNACKBAR = 'HIDE_SNACKBAR'
}
