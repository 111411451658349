import { OrderItemWithStatusAndOrderInfo } from '../../clients/foma/itemsClient';
import { PlatformItem } from '../../store/items/types';

const DEFAULT_JOBSHEET_EXPIRATION_IN_DAYS = 60;
const CIMPRESS_OPEN_JOBSHEET_EXPIRATION_IN_DAYS = 20;

export const isAcceptDisabled = (items: OrderItemWithStatusAndOrderInfo[]): boolean => {
  return !items.filter(item => item).length || !items.filter(item => item).every(item => {
    const newQuantity = item?.status?.statusDetails?.new?.quantity || 0;
    return newQuantity > 0 && !(item?.status?.statusDetails?.accepted);
  });
};

export const isRejectDisabled = (items: OrderItemWithStatusAndOrderInfo[]): boolean => {
  return !items.filter(item => item).length || !items.filter(item => item).some(item => {
    const statusDetails = item?.status?.statusDetails;
    return statusDetails && !!(statusDetails.accepted || statusDetails.production || statusDetails.new || statusDetails.unknown) && !statusDetails.cancelled;
  });
};

export const isSetToProductionDisabled = (items: OrderItemWithStatusAndOrderInfo[]): boolean => {
  return !items.filter(item => item).length || !items.filter(item => item).some(item => {
    const statusDetails = item?.status?.statusDetails;
    const enabled = statusDetails && !!(statusDetails.accepted || statusDetails.new || statusDetails.unknown);
    return enabled;
  });
};

export const isNotifyDelayDisabled = (items: OrderItemWithStatusAndOrderInfo[]): boolean => {
  return !items.filter(item => item).length || !items.filter(item => item).some(item => {
    const statusDetails = item?.status?.statusDetails;
    return statusDetails && !!(statusDetails.accepted || statusDetails.new || statusDetails.production || statusDetails.unknown);
  });
};

export const isHoldProductionDisabled = (items: OrderItemWithStatusAndOrderInfo[]): boolean => {
  return !items.filter(item => item).length || !items.filter(item => item).some(item => {
    const statusDetails = item?.status?.statusDetails;
    return statusDetails && !!(statusDetails.accepted || statusDetails.new || statusDetails.production || statusDetails.unknown);
  });
};

export const isJobsheetDisabled = (platformItems: PlatformItem[]): boolean => {
  // Get the earliest shipped item to calculate the expiration date from that timestamp
  // A shipped item is the one where 'fulfilled' status exists and the quantity in it is same as the ordered quantity
  const allItemsFulfilledDates = platformItems
    .map(item => item.statuses?.fulfilled?.quantity === item.quantity && item.statuses?.fulfilled?.updatedDate)
    .filter((date): date is string => !!date)
    .map(date => new Date(date).getTime());

  // If 'allItemsFulfilledDates' is empty, no ship items are selected. We don't need to disable the Jobsheet generation
  if (!allItemsFulfilledDates.length) {
    return false;
  }

  const candidateTimestamp = Math.min(...allItemsFulfilledDates);

  let daysToAdd = DEFAULT_JOBSHEET_EXPIRATION_IN_DAYS;

  // If we find even 1 item out of all items selected which is for cimpress-open merchant, then limit the jobsheet generation expiry for all to what cimpress-open demands.
  if (platformItems.find(platformItem => platformItem.merchantId === 'cimpress-open')) {
    daysToAdd = CIMPRESS_OPEN_JOBSHEET_EXPIRATION_IN_DAYS;
  }

  daysToAdd *= 86400000; // Convert days to milliseconds

  return (candidateTimestamp + daysToAdd) < Date.now();
};

export const isShipDisabled = (item: OrderItemWithStatusAndOrderInfo): boolean => {
  const statusDetails = item?.status?.statusDetails;
  const orderedQuantity = item?.orderedQuantity;
  return item?.isTestItem || (statusDetails?.cancelled?.quantity || 0) >= orderedQuantity || !!statusDetails?.rejected;
};

export const isBulkShipDisabled = (items: OrderItemWithStatusAndOrderInfo[]): boolean => {
  if (!items.filter(item => item).length) {
    return true;
  }

  return items
    .filter(item => item)
    .some(item => {
      const statusDetails = item?.status?.statusDetails;
      const orderedQuantity = item.orderedQuantity;
      return item.isTestItem || (statusDetails?.cancelled?.quantity || 0) >= orderedQuantity;
    });
};
