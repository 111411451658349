import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card, Breadcrumbs, Label, BreadcrumbItem, Dropdown, Alert } from '@cimpress/react-components';
import { ItemPriceSummary } from '@cimpress-technology/react-pricing-troubleshooter';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Comments } from '@cimpress-technology/react-comments';
import * as H from 'history';

import { usePlanResponse } from '../shipping/hooks/usePlanResponse';
import { renderAddressFields, renderDeliveryRequests } from '../../shared/deliveryAddress';
import { renderMerchantInfo } from '../../shared/merchantInfo';
import { renderFulfillerInfo } from '../../shared/fulfillerInfo';
import { renderProductDetails } from '../../shared/productDetails';

import { AppState } from '../../../store/store';
import { Loading } from '../../shared/loading';
import './itemDetails.scss';
import { renderShipments } from '../../shared/renderShipments';
import { useShipment } from '../../shared/hooks/useShipment';
import { useManufacturingDetails } from '../../shared/hooks/useManufacturingDetails';
import { renderItemInfo } from '../../shared/renderItemInfo';
import { renderSuggestedSchedule } from '../../shared/renderSuggestedSchedule';
import { DownloadDocumentButton } from '../../shared/buttons/downloadDocumentButton';
import { DownloadDeliveryNoteButton } from '../../shared/buttons/downloadDeliveryNoteButton';
import { PrintJobSheetButton } from '../../shared/buttons/printJobSheetButton';
import { AcceptOrderButton } from '../../shared/buttons/acceptOrderButton';
import { SetToProductionButton } from '../../shared/buttons/setToProductionButton';
import { ShipButton } from '../../shared/buttons/shipButton';
import { RejectButton } from '../../shared/buttons/rejectButton';
import { NotifyDelayButton } from '../../shared/buttons/notifyDelayButton';
import { usePlatformOrderItem } from '../../shared/hooks/usePlatformOrderItem';
import { useDateTimeFormatter } from '../../../utils/dateTimeUtils';
import { NotificationTypes } from '../../../clients/foma/notificationActionClient';
import { FomaItemState } from '../../../store/items/types';
import { getFomaOrderItem } from '../../../store/items/actions';
import { getClaims, getClaim } from '../../../clients/claims/claimsClient';
import { ArtworkChangeRequestButton } from '../../shared/buttons/artworkChangeRequestButton';
import { Claim } from '../../../clients/claims/claimModel';
import {
  isAcceptDisabled,
  isHoldProductionDisabled,
  isJobsheetDisabled,
  isNotifyDelayDisabled,
  isRejectDisabled,
  isSetToProductionDisabled,
  isShipDisabled
} from '../buttonStates';
import { useSelectedView } from '../../shared/hooks/useSelectedView';
import { ProductCategory } from '../../../models/products';
import { PlatformOrderItem } from '../../../clients/platformClient/platformItemsModel';
import { TFunction } from 'i18next';
import { LateOrForecastedLateLabel, DelayedLabel } from '../../shared/labels';
import { ClaimsLabelWithModal } from '../../shared/claimsLabelWithModal';
import { getNotificationsForItem } from '../../../store/notifications/actions';
import { TrackedLocalLink } from '../../../trackingLayer/trackedLocalLink';
import { renderManufacturingDetails } from '../../shared/renderManufacturingDetails';
import { useDeliveryRequests } from '../../shared/hooks/useDeliveryRequests';
import { ItemFulfillmentPreview } from '../../shared/itemFulfillmentPreview';
import { QuoteLabel } from '../../shared/quoteLabel';
import { ShipmentPlanViewer } from '../shipping/shipmentPlanViewer';
import { isProd } from '../../../utils/environmentProvider';
import { useFulfillerContacts } from '../../shared/hooks/useFulfillerContacts';
import { HoldProductionButton } from '../../shared/buttons/holdProductionButton';

const renderTestLabel = (t: TFunction) => {
  return <Label className='spacedLabel' text={t('shipping.test_item')} type="default" />;
};

const renderCancellationLabel = (t: TFunction, history, notificationId, includeLink) => {
  return <span onClick={includeLink ? () => history.push(`/pendingActions/cancellations/${notificationId}`) : () => {
    // Avoiding @typescript-eslint/no-empty-function failure
  }}>
    <Label className={`spacedLabel ${includeLink ? 'clickableLabel' : ''}`} text={t('shipping.cancellationPending')} type="danger" />
  </span>;
};

const renderCancelledLabel = (t: TFunction) => {
  return <Label className='spacedLabel' text={t('common.cancelled')} type="danger" />;
};

const renderAddressChangePendingLabel = (t: TFunction, history, includeLink) => {
  return <span onClick={includeLink ? () => history.push('/pendingActions') : () => {
    // Avoiding @typescript-eslint/no-empty-function failure
  }}>
    <Label className={`spacedLabel ${includeLink ? 'clickableLabel' : ''}`} text={t('shipping.addressChangePending')} type="warning" />
  </span>;
};

const renderArtworkChangePendingLabel = (t: TFunction, history, includeLink) => {
  return <span onClick={includeLink ? () => history.push('/pendingActions') : () => {
    // Avoiding @typescript-eslint/no-empty-function failure
  }}>
    <Label className={`spacedLabel ${includeLink ? 'clickableLabel' : ''}`} text={t('shipping.artworkChangePending')} type="warning" />
  </span>;
};

const renderShipmentRequirementChangePendingLabel = (t: TFunction, history, includeLink) => {
  return <span onClick={includeLink ? () => history.push('/pendingActions') : () => {
    // Avoiding @typescript-eslint/no-empty-function failure
  }}>
    <Label className={`spacedLabel ${includeLink ? 'clickableLabel' : ''}`} text={t('shipping.shipmentRequirementChangePending')} type="warning" />
  </span>;
};

const renderClaimLabel = (t: TFunction, itemId, claimId) => {
  if (!claimId) {
    return null;
  }
  return <ClaimsLabelWithModal itemId={itemId} />;
};

const renderReorderLabel = (t: TFunction, itemId, claimId) => {
  if (!claimId) {
    return null;
  }
  // important: for 'reorder' there claims API does not return any claims base
  return <ClaimsLabelWithModal claimId={claimId} labelStyle='warning' labelTitle={t('details.reprint')} />;
};

const renderErrors = (t: TFunction, itemId, orderItem) => {
  if (orderItem.error) {
    return <Alert type={'warning'} dismissible={false} message={t('details.errors.missingItem', { item: itemId })} />;
  }
  return null;
};

export const ItemDetailsPage: React.FC<{
  history: H.History;
}> = props => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  // @ts-ignore
  const { itemId } = useParams();
  const { selectedView } = useSelectedView();

  // TODO: It's details page, why care about 'table actions'
  const isTableActionRunning = useSelector((state: AppState) => state.items.table.isLoading || false);
  const shouldShowManufacturingCost = selectedView?.extensions?.ui?.screens?.itemDetails.manufacturingCost?.show !== false;

  // redux notifications
  // todo: it might be possible the requested item to be part of fulfiller for which notifications has not been loaded
  const notifications = useSelector((state: AppState) => Object.values((state.notifications || {}).notifications || {}));
  const itemNotifications = notifications.filter(a => a.items.filter(x => x.itemId === itemId).length > 0);
  const cancellationPending = itemNotifications.filter(n => n.type === NotificationTypes.CancellationRequest).length > 0;
  const addressChangePending = itemNotifications.filter(n => n.type === NotificationTypes.ChangeRequest).length > 0;
  const artworkChangePending = itemNotifications.filter(n => n.type === NotificationTypes.ArtworkChangeRequest).length > 0;
  const shipmentRequirementChangePending = itemNotifications.filter(n => n.type === NotificationTypes.ShipmentRequirementChangeRequest).length > 0;

  const { auth, settings, fulfillers } = useSelector((state: AppState) => state);
  const { accessToken } = auth;
  const { useMerchantIds } = settings;

  // items from redux
  const orderItem = useSelector((state: AppState): FomaItemState => state.items.byId[itemId || ''] || {});
  const [orderItemLoaded, setOrderItemLoaded] = useState(false);
  const [colors, setColors] = useState([]);

  useEffect(() => {
    if (!orderItemLoaded && !orderItem.isLoading) {
      setOrderItemLoaded(true);
      dispatch(getFomaOrderItem(itemId));
    }
  }, [dispatch, itemId, orderItem, orderItemLoaded]);

  const [notificationsLoaded, setNotificationsLoaded] = useState(false);
  useEffect(() => {
    if (!notificationsLoaded && orderItem.data) {
      setNotificationsLoaded(true);
      dispatch(getNotificationsForItem(orderItem.data));
    }
  }, [dispatch, notificationsLoaded, orderItem]);

  const dateFormatter = useDateTimeFormatter();
  const dateFormatterFunc = date => date ? dateFormatter(new Date(date)) : null;

  const { links, isTestItem, status, orderInfo, merchantInformation } = orderItem.data || {};
  const itemLink = links?.self?.href;
  const platformItemLink = ((links || {})['platform-item'] || {}).href;
  const shipmentLink = status?.statusDetails?.shipped?.links['shipments-collection']?.href;
  const manufacturingDetailsLink = ((links || {})['manufacturing-details'] || {}).href;

  const { loadingPlatformOrderItem, platformOrderItem } = usePlatformOrderItem(accessToken, platformItemLink, itemId || null);
  const { planResponse, loadingPlanResponse } = usePlanResponse(accessToken, orderItem.data);
  const { loadingShipments, shipments } = useShipment(accessToken, platformOrderItem?.itemId, shipmentLink);
  const { loadingManufacturingDetails, manufacturingDetails } = useManufacturingDetails(accessToken, manufacturingDetailsLink);
  const { deliveryRequestsMap } = useDeliveryRequests(accessToken, (orderItem.data?.deliveryDetails || []).map(a => a?.links?.self?.href).filter(a => a));

  const fulfillerId = orderInfo?.fulfiller?.fulfillerId;
  const { loadingFulfillerContacts, fulfillerContacts } = useFulfillerContacts(accessToken, fulfillerId);
  
  const categories = useSelector<AppState, ProductCategory[]>(state => state.productCategories?.categories?.data || []);
  const categoriesLeafMap = {};
  categories.forEach(c => categoriesLeafMap[c.name] = c.isLeaf);

  const isReorderItem = !!platformOrderItem?._links.reorderClaim?.href;
  const [reorderClaim, setReorderClaim] = useState({} as Claim | undefined);
  useEffect(() => {
    const f = async () => {
      if (platformOrderItem?._links.reorderClaim?.href) {
        const claim = await getClaim(accessToken, platformOrderItem?._links.reorderClaim?.href);
        setReorderClaim(claim);
      }
    };
    f();
  }, [accessToken, platformOrderItem]);

  const hasClaims = !!platformOrderItem?._links.claims?.href;
  const isQuote = !!platformOrderItem?._links.fulfillerQuoteItem?.href;
  const [standardClaims, setStandardClaims] = useState({} as Claim[] | undefined);
  useEffect(() => {
    const f = async () => {
      if (platformOrderItem?._links.claims?.href) {
        const claims = await getClaims(accessToken, platformOrderItem?._links.claims?.href);
        setStandardClaims(claims);
      }
    };
    f();
  }, [accessToken, platformOrderItem]);

  const setSpotColors = spotColors => {
    setColors(spotColors);
  };

  const fulfiller = fulfillerId && fulfillers?.fulfillers?.isLoading === false ? fulfillers?.fulfillers?.data?.find(d => d.fulfillerId === fulfillerId) : null;

  const shownItemId = useMerchantIds ? merchantInformation?.itemId || itemId : itemId;
  const cancelledQuantity = status?.statusDetails.cancelled?.quantity || 0;

  const includeNotificationLinks = selectedView?.extensions?.ui?.screens?.pendingActions?.show;

  // TBD: Add here
  const actionRunning = orderItem.isLoading || isTableActionRunning;

  // HACK:
  const deliveryAddressAtOrderLevel = orderInfo?.destinationAddress;

  return (
    <div className='container-fluid'>
      <Breadcrumbs>
        <BreadcrumbItem>
          <TrackedLocalLink item={'breadcrumbs.home'} to={'/'}>{t('breadcrumbs.home')}</TrackedLocalLink>
        </BreadcrumbItem>
        <BreadcrumbItem><TrackedLocalLink item={'breadcrumbs.home.items'} to={{ pathname: '/items', state: { backToItemsPage: true } }}>{t('details.items')}</TrackedLocalLink></BreadcrumbItem>
        <BreadcrumbItem>{shownItemId?.toUpperCase()}</BreadcrumbItem>
      </Breadcrumbs>

      <Card>
        {!orderItem.data && !loadingPlatformOrderItem
          ? renderErrors(t, itemId, orderItem)
          : <Fragment>
            <div className='actionOptions'>
              <div className='leftActions'>
                {selectedView?.extensions.ui.actions.accept.show
                  ? <AcceptOrderButton t={t} disabled={actionRunning || isAcceptDisabled([orderItem.data])} dispatch={dispatch} items={[orderItem.data]} refreshItemList={false} />
                  : null}
                {selectedView?.extensions.ui.actions.setToProduction.show
                  ? <SetToProductionButton disabled={actionRunning || isSetToProductionDisabled([orderItem.data])} dispatch={dispatch} items={[orderItem.data]} refreshItemList={false} />
                  : null}
                <ShipButton t={t} disabled={actionRunning || isShipDisabled(orderItem.data)} history={props.history} items={[orderItem.data]} />
                {selectedView?.extensions.ui.actions.reject.show
                  ? <Dropdown title={t('actions.otherActions')} >
                    <NotifyDelayButton dropDown disabled={actionRunning || isNotifyDelayDisabled([orderItem.data])} items={[orderItem.data]} refreshItemList={false} />
                    <RejectButton dropDown disabled={actionRunning || isRejectDisabled([orderItem.data])} dispatch={dispatch} items={[orderItem.data]} refreshItemList={false} />
                    <HoldProductionButton dropDown disabled={actionRunning || isHoldProductionDisabled([orderItem.data])} dispatch={dispatch} items={[orderItem.data]} refreshItemList={false} />
                  </Dropdown>
                  : <NotifyDelayButton disabled={actionRunning} items={[orderItem.data]} className={'btn-warning'} refreshItemList={false} />}
              </div>
              <div className='rightActions'>
                <PrintJobSheetButton disabled={actionRunning || loadingPlatformOrderItem || isJobsheetDisabled([platformOrderItem])} history={props.history} items={[orderItem.data]} tooltipMessage={t('jobsheet.disableJobsheetTooltipMessage')}/>
                <DownloadDocumentButton dispatch={dispatch} items={[orderItem.data]} disabled={false} />
                {selectedView?.extensions?.ui?.actions?.downloadDeliveryNote?.show
                  ? <DownloadDeliveryNoteButton dispatch={dispatch} items={[platformOrderItem as PlatformOrderItem]} disabled={!platformOrderItem?.deliveryNoteUrl} />
                  : null
                }
                {selectedView?.extensions.ui.actions.artworkChange?.show
                  ? <ArtworkChangeRequestButton platformItemId={platformOrderItem?.itemId} disabled={actionRunning} />
                  : null}
              </div>
            </div>
            {orderItem.isLoading || loadingPlanResponse
              ? <Loading message={`${t('common.loading')}...`} />
              : <div className='flexContainer'>
                <div className='flexChildGrow' >
                  <div>
                    <div className='itemDetailsCaptionTitle'>
                      <div className='itemDetailsCaptionTitleName'>
                        {orderItem.data ? `${orderItem.data.orderedQuantity}x ${orderItem.data.product?.name}` : null}
                      </div>
                      <div className='itemDetailsCaptionTitleLabels'>
                        {isTestItem ? renderTestLabel(t) : null}
                        {cancellationPending
                          ? renderCancellationLabel(t,
                            props.history,
                            itemNotifications.find(n => n.type === NotificationTypes.CancellationRequest)?.notificationId,
                            includeNotificationLinks)
                          : null}
                        {addressChangePending ? renderAddressChangePendingLabel(t, props.history, includeNotificationLinks) : null}
                        {artworkChangePending ? renderArtworkChangePendingLabel(t, props.history, includeNotificationLinks) : null}
                        {shipmentRequirementChangePending ? renderShipmentRequirementChangePendingLabel(t, props.history, includeNotificationLinks) : null}
                        {cancelledQuantity > 0 ? renderCancelledLabel(t) : null}
                        <LateOrForecastedLateLabel currentItem={orderItem?.data} plan={planResponse} selectedView={selectedView} />
                        <DelayedLabel currentPlatformItem={platformOrderItem as PlatformOrderItem} />
                        {isQuote ? (
                          <QuoteLabel
                            itemId={platformOrderItem?.itemId}
                            attributes={platformOrderItem?.fulfillmentAttributes}
                            quoteHref={platformOrderItem?._links.fulfillerQuoteItem?.href} />
                        ) : null}
                        {hasClaims ? renderClaimLabel(t, platformOrderItem?.itemId, (standardClaims && standardClaims.length > 0) ? standardClaims[0]?.id : null) : null}
                        {isReorderItem ? renderReorderLabel(t, platformOrderItem?.itemId, reorderClaim?.id) : null}
                      </div>
                    </div>

                    <div className='item-details-box'>
                      {renderItemInfo(t, orderItem.data, useMerchantIds, dateFormatterFunc, selectedView?.extensions)}
                    </div>

                    {accessToken && platformOrderItem?.itemId
                      ? <div className='item-details-box'>
                        <h5>{t('details.pricingInformation')} &nbsp;</h5>
                        <div className='item-details-price-container'>
                          <ItemPriceSummary
                            merchantId={orderInfo.merchantInformation.id}
                            itemId={platformOrderItem?.itemId}
                            locale={i18n.language}
                            accessToken={accessToken}
                            createdDate= {orderInfo.createdDate}
                            buyerAccountId={platformOrderItem._links.buyerAccount.name}
                            sellerAccountId= {platformOrderItem._links.sellerAccount.name}
                            showManufacturingCost={shouldShowManufacturingCost}
                          />
                        </div>
                      </div>
                      : null}

                    {renderSuggestedSchedule(t, planResponse, dateFormatterFunc)}
                    <div className='item-details-box'>
                      <h5>{t('details.shipmentPlan.title')}</h5>
                      <ShipmentPlanViewer item={platformOrderItem} accessToken={accessToken} />
                    </div>

                    {shipmentLink
                      ? <div className='item-details-box'>
                        <h5>{t('details.shipments')} &nbsp;</h5>
                        {loadingShipments
                          ? <Loading message={`${t('common.loading')}...`} />
                          : renderShipments(t, shipments, dateFormatterFunc)}
                      </div>
                      : null}

                    <div className='item-details-box'>
                      {renderProductDetails(t, orderItem.data, selectedView?.extensions, categoriesLeafMap)}
                    </div>

                    {loadingManufacturingDetails
                      ? <Loading message={`${t('common.loading')}...`} />
                      : renderManufacturingDetails(t, manufacturingDetails, colors)}

                    {selectedView?.extensions.ui.screens.itemDetails.deliveryAddress.show
                      ? <div className='item-details-box'>
                        <h5>{t('shipping.delivery_address')}</h5>
                        {deliveryAddressAtOrderLevel
                          ? renderAddressFields(t, deliveryAddressAtOrderLevel)
                          : renderDeliveryRequests(t, orderItem.data?.orderedQuantity, (orderItem.data?.deliveryDetails || []), deliveryRequestsMap)}
                      </div>
                      : null}

                    <div className='item-details-box'>
                      <h5>{t('details.merchant')}</h5>
                      {renderMerchantInfo(t, orderInfo, merchantInformation?.itemId)}
                    </div>

                    {loadingFulfillerContacts
                      ? <Loading message={`${t('common.loading')}...`} />
                      : <div className='item-details-box'>
                        <h5>{t('details.fulfiller')}</h5>
                        {renderFulfillerInfo(t, fulfiller, fulfillerContacts)}
                      </div>
                    }

                    {itemLink
                      ? <div className='item-details-box-comments'>
                        <h5>{t('details.comments')}</h5>
                        <Comments
                          locale={i18n.language}
                          resourceUri={itemLink}
                          accessToken={accessToken}
                          textOverrides={{
                            placeholder: t('comments.placeholder'),
                            subscribe: t('comments.subscribe'),
                            unsubscribe: t('comments.unsubscribe'),
                            postComment: t('comments.postComment')
                          }}
                          showVisibilityLevels={false}
                          autoFocus={false}
                          enforceVisibilityLevel={'internal'}
                          positionSelf={'right'}
                          emailing={{
                            enabled: selectedView?.extensions.features.sendEmailOnPlacingComment.enabled,
                            newCommentsTemplateId: 'c80543d4-60c3-4be1-b083-0b39c6d3a5e1',
                            newCommentsTemplatePayload: {
                              links: {
                                order: {
                                  href: itemLink,
                                  rel: 'order'
                                }
                              }
                            }
                          }}
                          env ={ isProd ? 'production' : 'integration'}
                        />
                      </div>
                      : null}
                  </div>
                </div>
                <div className='flexChild item-details-previews'>
                  <ItemFulfillmentPreview
                    accessToken={accessToken}
                    manufacturingReadyDataUrl={platformOrderItem?.manufacturingReadyDataUrl}
                    fomaPreviewUrl={orderItem?.data?.links?.preview?.href}
                    size={'l'}
                    minimal={false}
                    hideIndicators={false}
                    skuCode={orderItem.data?.product?.sku}
                    documentReferenceUrl={platformOrderItem?.documentReferenceUrl}
                    variableAttributes={platformOrderItem?.variableAttributes || []}
                    setSpotColors={setSpotColors}
                    standardOutputUrl={platformOrderItem?._links.standardOutputUrl?.href}
                  />
                </div>
              </div>}
          </Fragment>}
      </Card >
    </div >
  );
};
